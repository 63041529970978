import { createApp, DirectiveBinding } from "vue";
import App from "./App.vue";
import "./assets/index.css";

import { library } from "../node_modules/@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "../node_modules/@fortawesome/vue-fontawesome";

/* import specific icons */
import { fab } from "../node_modules/@fortawesome/free-brands-svg-icons";
import Vue3Lottie from "vue3-lottie";
/* add icons to the library */
library.add(fab);

const vIntersect = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            el.classList.add(binding.value); // Добавить класс анимации
          } else {
            el.classList.remove(binding.value); // Удалить класс анимации
          }
        });
      },
      { threshold: 0.2 } // Порог видимости
    );

    observer.observe(el);
  },
};

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(Vue3Lottie)
  .directive("intersect", vIntersect)
  .mount("#app");
