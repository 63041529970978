<script setup lang="ts">
import { defineAsyncComponent } from "vue";
const Navbar = defineAsyncComponent(() => import("./components/Navbar.vue"));
const Hero = defineAsyncComponent(() => import("./components/Hero.vue"));
const AboutCourse = defineAsyncComponent(
  () => import("./components/AboutCourse.vue")
);
const ForWho = defineAsyncComponent(() => import("./components/ForWho.vue"));

const CourseProgram = defineAsyncComponent(
  () => import("./components/CourseProgram.vue")
);
const Students = defineAsyncComponent(
  () => import("./components/Students.vue")
);
const Pricing = defineAsyncComponent(() => import("./components/Pricing.vue"));

const FAQ = defineAsyncComponent(() => import("./components/FAQ.vue"));
const Cta = defineAsyncComponent(() => import("./components/Cta.vue"));
const Footer = defineAsyncComponent(() => import("./components/Footer.vue"));

const AboutAuthor = defineAsyncComponent(
  () => import("./components/AboutAuthor.vue")
);
</script>

<template>
  <Navbar />
  <Hero />
  <!-- Головна -->
  <AboutCourse />
  <!-- Про курс -->
  <ForWho />
  <!-- Для кого -->
  <CourseProgram />
  <!-- Програма курсу -->
  <Students />
  <!-- Учні -->
  <Pricing />
  <!-- <DemoCourse /> -->
  <!-- Демо курс -->
  <AboutAuthor />
  <!-- Про автора -->
  <FAQ />
  <Cta />
  <Footer />
</template>
<style scoped>
html,
body {
  overflow-x: hidden;
}
</style>
